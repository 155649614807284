// LocationPopup.js
import React from 'react';

const LocationPopup = ({ onSelectCity }) => {
  const handleCitySelect = (city) => {
    onSelectCity(city);
  };

  return (
    <div className="location-popup-overlay">
      <div className="location-popup">
        <h3>Select your location:</h3>
        <div className="location-buttons">
          <button className="location-button" onClick={() => handleCitySelect('Sydney')}>
            Sydney
          </button>
          <button className="location-button" onClick={() => handleCitySelect('Melbourne')}>
            Melbourne
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationPopup;