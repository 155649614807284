import { serverAddress } from '../App';

export const fetchCity = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json?token=4ebdcbde3d2a01');
      const data = await response.json();
      return data.city;
    } catch (error) {
      console.error('Error fetching city:', error);
      return null;
    }
  };
  
  export const startConversation = async () => {
    try {
      const response = await fetch(`${serverAddress}/start`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const { thread_id } = await response.json();
      console.log(`starting new conversation with thread id: ${thread_id}`);
      return thread_id;
    } catch (error) {
      console.error('Error starting conversation:', error);
      return null;
    }
  };