import React from 'react';

function MessageForm({ inputValue, setInputValue, handleSendMessage, isFetchingLocation, isRunActive }) {
  // Determine the placeholder text based on the state.
  let placeholderText = 'Type a message...';
  if (isFetchingLocation) {
    placeholderText = 'Fetching location...';
  } else if (isRunActive) {
    placeholderText = 'Chat bot is busy. Please wait...';
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSendMessage();
      }}
      className="message-form"
    >
      <textarea
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        placeholder={placeholderText}
        className="message-input"
        rows="3"
        // Textarea should be disabled only when fetching location, not when the chatbot is busy.
        disabled={isFetchingLocation}
      ></textarea>
      <button
        type="submit"
        className="send-button"
        // Button should not be disabled when chatbot is busy, only during location fetch.
        disabled={isRunActive}
      >
        Send
      </button>
    </form>
  );
}

export default MessageForm;
