import React, { useEffect, useRef } from 'react';

function ChatBox({ messages, waitingForServer, isLoading, handleMessageDisplay, onScroll }) {
  const chatBoxRef = useRef(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-box" ref={chatBoxRef} >
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.sender}`}>
          {handleMessageDisplay(message)}
        </div>
      ))}
      {(waitingForServer || isLoading) && (
        <div className="message bot">
          <div className='padding'>
            {waitingForServer ? 'Waiting for server...' : <div className="dot-flashing"></div>}
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBox;