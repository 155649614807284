import React, { useRef } from 'react';
import logo from '../Bonid_logo.png';

function ChatIntroduction({ city }) {
  const chatIntroductionRef = useRef(null);

  return (
    <nav className="chat-navbar" ref={chatIntroductionRef}>
      <div className="navbar-links">
        <img src={logo} alt="Chat Bot Logo" className="navbar-logo" />
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdy5Cp5Wtrt2awt5guKePUGrUp6L4gbuDxLvvGT18ANQf9i9Q/formrestricted" target="_blank" rel="noopener noreferrer" className="navbar-link">
          Feedback
        </a>
        {city && <span className="navbar-location">Location: {city}</span>}
      </div>
    </nav>
  );
}

export default ChatIntroduction;
