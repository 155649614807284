// App.js
import React, { useState, useEffect } from 'react';
import LocationPopup from './components/LocationPopup';
import './App.css';
import ChatIntroduction from './components/ChatIntroduction';
import ChatBox from './components/ChatBox';
import SuggestedPrompts from './components/SuggestedPrompts';
import MessageForm from './components/MessageForm';
import { fetchCity, startConversation } from './utils/utils';

function App() {
  const [messages, setMessages] = useState([
    { text: 'Welcome to Bondi Lines. To get you matched with events ASAP please provide your \n \n 1. Prefered Music Genre (optional) \n 2.Your Age', sender: 'bot' }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [city, setCity] = useState('');
  const [waitingForServer, setWaitingForServer] = useState(false);
  const [initMessage, setInitMessage] = useState(0);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [isRunActive, setIsRunActive] = useState(false);
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState({});

  const toggleAccordion = (eventId) => {
    setExpandedAccordions((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId],
    }));
  };

  useEffect(() => {
    const fetchCityData = async () => {
      setIsFetchingLocation(true);
      const cityData = await fetchCity();

      if (cityData === 'Sydney' || cityData === 'Melbourne') {
        setCity(cityData);
      } else {
        setShowLocationPopup(true);
      }

      setIsFetchingLocation(false);
    };

    fetchCityData();
  }, []);

  const handleCitySelect = (selectedCity) => {
    setCity(selectedCity);
    setShowLocationPopup(false);
  };

  const fetchChatGPTResponse = async (threadId, message) => {
    if (!message || !threadId) {
      throw new Error('Missing threadId or message');
    }
  
    setIsLoading(true);
    setWaitingForServer(false);
  
    const requestBody = {
      thread_id: threadId,
      message,
    };
  
    try {
      const response = await fetch(`${serverAddress}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
  
      let botResponse = '';
      let isDone = false;
  
      while (!isDone) {
        const { value, done } = await reader.read();
        isDone = done;
        setIsLoading(false);
        setWaitingForServer(false);
        setIsRunActive(false);
        if (value) {
          const chunk = decoder.decode(value);
  
          if (chunk.startsWith('{"eventsData":')) {
            botResponse += chunk;
          } else {
            botResponse += chunk;
          }
  
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages.filter(msg => msg.sender !== 'feedback');
            const lastMessage = updatedMessages[updatedMessages.length - 1];
  
            if (lastMessage && lastMessage.sender === 'bot') {
              lastMessage.text = botResponse;
            } else {
              updatedMessages.push({ text: botResponse, sender: 'bot' });
            }
  
            return [...updatedMessages];
          });
        }
      }
  
      setIsLoading(false);
      setIsRunActive(false);
    } catch (error) {
      setIsLoading(false);
      setIsRunActive(false);
      console.error('Error during fetchChatGPTResponse:', error);
      throw error;
    }
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim() || isRunActive) return;
  
    setIsRunActive(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: '', sender: 'system' },
    ]);
  
    let currentThreadId = threadId;
    if (!currentThreadId) {
      setWaitingForServer(true);
      currentThreadId = await startConversation();
      if (!currentThreadId) {
        console.error('Failed to start a new conversation.');
        setWaitingForServer(false);
        setIsRunActive(false);
        return;
      }
      setThreadId(currentThreadId);
    }
  
    try {
      if (isRunActive) {
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { text: 'Chat bot is busy. Please wait for the previous message to be processed.', sender: 'system' },
        ]);
        return;
      }
  
      const userMessageText = inputValue;
      const userMessage = { text: userMessageText, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages.slice(0, -1), userMessage]);
  
      setInputValue('');
  
      fetchChatGPTResponse(currentThreadId, `${userMessageText} \n\nCity based off of IP Address: ${city}.`)
        .then(responseData => {
          console.log(responseData.events);
          if (responseData && responseData.response && responseData.response.length > 0 && responseData.response[0].text) {
            const botResponseText = responseData.response[0].text.value;
            setMessages((prevMessages) => {
              const updatedMessages = prevMessages.filter(msg => msg.sender !== 'feedback');
              return [...updatedMessages, { text: botResponseText, sender: 'bot' }, { text: 'Feedback', sender: 'feedback' }];
            });
          } else if (responseData && responseData.events) {
            const eventsData = responseData.events;
            setMessages((prevMessages) => {
              const updatedMessages = prevMessages.filter(msg => msg.sender !== 'feedback');
              return [...updatedMessages, { text: JSON.stringify(eventsData), sender: 'bot' }, { text: 'Feedback', sender: 'feedback' }];
            });
          } else {
            setMessages((prevMessages) => [
              ...prevMessages.filter(msg => msg.sender !== 'feedback'),
              { text: 'Error getting response1', sender: 'bot' }
            ]);
          }
        })
        .catch(error => {
          console.log('error caught');
          console.error('Error during fetchChatGPTResponse:', error);
          setMessages((prevMessages) => [
            ...prevMessages.filter(msg => msg.sender !== 'feedback'),
            { text: 'Error getting response', sender: 'bot' }
          ]);
        })
        .finally(() => {
          setIsLoading(false);
          setWaitingForServer(false);
          setIsRunActive(false);
        });
    } catch (error) {
      console.error('Error checking run status:', error);
      setIsRunActive(false);
    }
  };

  function handleMessageDisplay(message) {
    if (message.sender === 'bot') {
      const jsonStartIndex = message.text.indexOf('[');
      const jsonEndIndex = message.text.lastIndexOf('}]') + 2;
      let jsonPart = '';
      let textPart = '';
  
      if (jsonStartIndex !== -1 && jsonEndIndex > jsonStartIndex) {
        jsonPart = message.text.substring(jsonStartIndex, jsonEndIndex);
        textPart = message.text.substring(0, jsonStartIndex) + message.text.substring(jsonEndIndex);
      } else {
        textPart = message.text;
      }
  
      let eventsData = [];
      let eventsContent = null;
  
      if (jsonPart) {
        try {
          eventsData = JSON.parse(jsonPart);
          eventsContent = (
            <>
              {/* Desktop/Tablet Event Rendering */}
              <div className="events-container-desktop">
                {eventsData.map((event, index) => {
                  const startDate = new Date(event.startDate);
                  const endDate = new Date(event.endDate);
                  const startString = `Starts ${startDate.toLocaleString('en-US', { weekday: 'long', hour: '2-digit', minute: '2-digit', day: '2-digit', month: 'short' })}`;
                  const endString = `Finishes ${endDate.toLocaleString('en-US', { weekday: 'long', hour: '2-digit', minute: '2-digit', day: '2-digit', month: 'short' })}`;

                  return (
                    <a
                      key={index}
                      href={`https://bondilinesapp.com/event/${event.slug}`}
                      className="event-item"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={event.image} alt={event.name} className="event-image" />
                      <div className="event-details">
                        <h3 className="event-title">{event.name}</h3>
                        <p className="event-info">
                          <span className="event-time">{`${startString}`}</span>
                          <span className="event-genre">
                            Genre: {event.musicGenres.map(genre => genre.name).join(', ')}
                          </span>
                          <span className="event-venue">Venue: {event.venue.name}</span>
                        </p>
                        <div className="event-price-tags">
                          {event.priceNote && (
                            <div className="event-price">
                              <span className="event-price-note">{event.priceNote}</span>
                            </div>
                          )}
                          {event.hasExclusiveDeal && (
                            <div className="event-deal">{event.exclusiveDeal}</div>
                          )}
                          {event.hasLiveDeal && (
                            <div className="event-deal">Live Deal</div>
                          )}
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>

              {/* Mobile Event Rendering */}
              <div className="events-container-mobile">
            {eventsData.map((event, index) => {
              const startDate = new Date(event.startDate);
              const endDate = new Date(event.endDate);
              const startString = `Starts ${startDate.toLocaleString('en-US', { weekday: 'long', hour: '2-digit', minute: '2-digit', day: '2-digit', month: 'short' })}`;
              const endString = `Finishes ${endDate.toLocaleString('en-US', { weekday: 'long', hour: '2-digit', minute: '2-digit', day: '2-digit', month: 'short' })}`;

              return (
                <div key={event.id} className={`event-accordion ${expandedAccordions[event.id] ? 'active' : ''}`}>
                    <div
                      className="event-header"
                      onClick={() => toggleAccordion(event.id)}
                    >
                    <img src={event.image} alt={event.name} className="event-image" />
                    <h3 className="event-title">{event.name}</h3>
                    <span className="event-expand-icon">+</span>
                  </div>
                  <div className="event-details">
                    <p className="event-info">
                      <span className="event-time">{`${startString}`}</span>
                      <span className="event-genre">
                        Genre: {event.musicGenres.map(genre => genre.name).join(', ')}
                      </span>
                      <span className="event-venue">Venue: {event.venue.name}</span>
                    </p>
                    <div className="event-price-tags">
                      {event.priceNote && (
                        <div className="event-price">
                          <span className="event-price-note">{event.priceNote}</span>
                        </div>
                      )}
                      {event.hasExclusiveDeal && (
                        <div className="event-deal">{event.exclusiveDeal}</div>
                      )}
                      {event.hasLiveDeal && (
                        <div className="event-deal">Live Deal</div>
                      )}
                    </div>
                    <a
                      href={`https://bondilinesapp.com/event/${event.slug}`}
                      className="event-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Event
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    } catch (error) {
      console.error('Error parsing JSON data:', error);
      textPart = (
        <>
          <div className="ghost-event">
            <div className="ghost-event-image"></div>
            <div className="ghost-event-details">
              <div className="ghost-event-title"></div>
              <div className="ghost-event-info">
                <div className="ghost-event-time"></div>
                <div className="ghost-event-genre"></div>
                <div className="ghost-event-venue"></div>
              </div>
              <div className="ghost-event-price-tags">
                <div className="ghost-event-price"></div>
                <div className="ghost-event-deal"></div>
              </div>
            </div>
          </div>
          <p>Fetching retrieved events... This shouldn't take long.</p>
        </>
      );
    }
      }
      let formattedText = textPart;

      if (typeof textPart === 'string') {
        const parts = textPart.split(/\*\*(.*?)\*\*/g);
        formattedText = parts.map((part, index) =>
          index % 2 === 0 ? part : <span style={{ color: "#2f86f7", fontWeight: 700 }} key={index}>{part}</span>
        );
      }
  
      return (
        <div>
          {eventsContent}
          {eventsContent && Array(3).fill(null).map((_, index) => <p key={index} />)}
          {formattedText}
        </div>
      );
    } else {
      return message.text;
    }
  }

  return (
    <div className="chat-container">
      {showLocationPopup && <LocationPopup onSelectCity={handleCitySelect} />}
      <ChatIntroduction city={city} />
      <ChatBox
        messages={messages}
        waitingForServer={waitingForServer}
        isLoading={isLoading}
        handleMessageDisplay={handleMessageDisplay}
      />
      <MessageForm
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleSendMessage={handleSendMessage}
        isFetchingLocation={isFetchingLocation}
        isRunActive={isRunActive}
      />
    </div>
  );
}

export default App;
export const serverAddress = "https://9035ecd3-32c0-42a7-96e9-42e557fdcc20-00-28ja58g1mmf69.picard.replit.dev"; 